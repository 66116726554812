<template>
    <div id="product-list">
        <div class="content">
            <div class="search-bar" @keyup.enter="search">
                <span>
                  <i class="icon-search" @click="search"></i>
                  <input type="text" maxlength="24" v-model="searchText" placeholder="商品型号:9326">
                </span>
            </div>
            <div class="list">
                <div class="lf">
                    <div class="filter-bar">
                        <h2 @click = "showSelect('popSelect1')">{{ $t('lang.filter') }}</h2>
                        <ul v-if="leftbar.length > 0 && isSeriesFilter">
                            <li v-for="(item, index) in leftbar" :key="index" :class="{'selected': filterIdx == index}" @click="chooseProduct(index)">
                                {{ isEn ? item.enName : item.cnName }}
                            </li>
                        </ul>
                        <ul v-if="seriesBar.length > 0 && !isSeriesFilter">
                            <li v-for="(item, index) in seriesBar" :key="index" @click="chooseSeries(index)">
                                {{ isEn ? item.enName : item.cnName }}
                            </li>
                        </ul>
                        <h2 @click = "showSelect('popSelect2')" v-if="isSeriesFilter">{{ $t('lang.particles') }}</h2>
                        <ul v-if="isSeriesFilter">
                            <li v-for="(item, index) in filterParticles" :key="index" :class="{'selected': filterParticleId === item.id}" @click="chooseParticle(index)">
                                {{ isEn ? item.enName : item.cnName }}
                            </li>
                        </ul>

                        <div v-show = "popSelect1 || popSelect2" class = "popBg" @click = "hideSelect"></div>
                        <ul v-show = "popSelect1" class = "h5_1" v-if="leftbar.length > 0">
                            <li v-for="(item, index) in leftbar" :key="index" :class="{'selected': filterIdx == index}" @click="chooseProduct(index)">
                                {{ isEn ? item.enName : item.cnName }}
                            </li>
                        </ul>
                        <ul v-show = "popSelect2" class = "h5_2">
                            <li v-for="(item, index) in filterParticles" :key="index" :class="{'selected': filterParticleId === item.id}" @click="chooseParticle(index)">
                                {{ isEn ? item.enName : item.cnName }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="series" v-if="productList.length > 0">
                    <div :class="['item', filterId || filterParticleId ? 'sub-item' : '']" v-for="(item, index) in productList" :key="index" @click="productClicked(index)">
                        <img :src="staticPath + (isEn ? item.enLogo : item.cnLogo)" alt="">
                        <div class="text" :style="{cursor: filterId ? 'pointer' : 'auto'}">
                            <p :style="{width: filterIdx === 0 ? '100%' : '0'}" v-if="filterIdx === 0 && filterParticleIdx === ''">{{ isEn ? item.enName : item.cnName }}</p>
                            <div class="lf" v-if="filterIdx !== 0 || filterParticleIdx !== ''">
                                <p>{{ isEn ? item.enName : item.cnName }}</p>
                                <span>数号: {{ item.model }}</span>
                            </div>
                            <button v-if="filterIdx !== 0 || filterParticleIdx !== ''" @click="checkDetail(item.id, index)">查看详情</button>
                        </div>
                    </div>
                </div>
                <div class="list-empty" v-else>
                    <div>
                        <img :src="require('../../assets/images/empty_robot.gif')" alt="">
                    </div>
                    <p>暂无内容!~</p>
                </div>
            </div>
            <div class="pagination" v-if="productList.length > 0">
                <el-pagination
                    background
                    layout="prev, pager, next"
                    @current-change="changePagination"
                    :current-page.sync="pageCurrent"
                    :page-size="pageSize"
                    :total="total">
                </el-pagination>
            </div>
        </div>

        <div class="dialog" :style="{'display': showDialog ? 'block': 'none'}">
            <div :class="['detail-box', 'animate__animated', dialogVisible ? 'animate__fadeInUp' : 'animate__fadeOutDown']">
                <i class="icon-close" @click="closeDialog"></i>
                <div class="img">
                    <swiper ref="mySwiper" :options="swiperOptions">
                        <swiper-slide v-for="(item, index) in detail.files" :key="index">
                            <div class="banner-box">
                                <img :src="staticPath + item.fileUrl" alt/>
                            </div>
                        </swiper-slide>
                        <div class="swiper-button-prev" slot="button-prev"></div>
                        <div class="swiper-button-next" slot="button-next"></div>
                    </swiper>
                </div>
                <div class="detail-info" v-if="detail">
                    <h2>{{ isEn ? detail.enName : detail.cnName }}</h2>
                    <ul>
                        <li v-if="detail.model">型 号: {{ detail.model }}</li>
                        <li v-if="detail.particleCounts">颗粒数: {{ detail.particleCounts }}</li>
                        <li v-if="detail.dollsCounts">公仔数量: {{ detail.dollsCounts }}</li>
                        <li v-if="detail.ageAdaptation">适用年龄: {{ detail.ageAdaptation }}</li>
                        <li class="manual" v-if="detail.hasManual">
                            <span>说明书:</span>
                            <p @click="descriptionDownload(detail.fileExternalUrl)">点击查看{{ isEn ? detail.enName : detail.cnName }}电子拼装说明书</p>
                        </li>
                        <li v-if="isEn ? detail.enMaterial : detail.cnMaterial">材 料: {{ isEn ? detail.enMaterial : detail.cnMaterial }}</li>
                        <li v-if="isEn ? detail.enPackagingType : detail.cnPackagingType">包装形式: {{ isEn ? detail.enPackagingType : detail.cnPackagingType }}</li>
                        <li v-if="detail.size">包装尺寸: {{ detail.size }}</li>
                    </ul>
                    <div class="description" @click="descriptionDownload(detail.downloadUrl)" v-if="detail.hasDownload">点击下载{{ isEn ? detail.enName : detail.cnName }}</div>
<!--                    <div class="description" @click="descriptionDownload(detail.fileExternalUrl)" v-if="detail.hasManual">点击查看{{ isEn ? detail.enName : detail.cnName }}电子拼装说明书</div>-->
                    <div class="purchase" v-if="detail.jingDongUrl || detail.jingDongUrl">
                        <p>购买方式:</p>
                        <a :href="detail.jingDongUrl" target="_blank" v-if="detail.jingDongUrl">京东商城</a>
                        <a :href="detail.taoBaoUrl" target="_blank" v-if="detail.taoBaoUrl">天猫商城</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import "swiper/dist/css/swiper.css";
    import { swiper, swiperSlide } from "vue-awesome-swiper";
    import {urls, staticPath} from '@/lib/common';
    export default {
        name: "ProductList",
        components: {
            swiper,
            swiperSlide,
        },
        data() {
            return {
                staticPath,
                swiperOptions: {
                    grabCursor: false,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                },
                leftbar: [],
                seriesBar: [],
                seriesIdx: null,
                pageCurrent: 1,
                pageSize: 12,
                total: 0,
                filterParticles: [
                    {
                        id: 'lt100',
                        cnName: '100以内',
                        enName: 'less 100',
                    }, {
                        id: 'lt200',
                        cnName: '200以内',
                        enName: 'less 200',
                    }, {
                        id: 'lt300',
                        cnName: '300以内',
                        enName: 'less 300',
                    }, {
                        id: 'gt300',
                        cnName: '300以上',
                        enName: 'more than 300',
                    }
                ],
                filterParticleId: '',
                filterParticleIdx: '',
                filterId: '',
                filterIdx: 0,
                isSeriesFilter: true,
                showDialog: false,
                dialogVisible: false,
                productList: [],
                productIdx: 0,
                parentId: '',
                navId: '',
                searchText: '',
                isEn: false,
                detail: '',

                popSelect1:false,
                popSelect2:false
            }
        },
        computed: {
            swiper() {
                return this.$refs.mySwiper.$swiper;
            },
        },
        watch: {
            "$i18n.locale": function(val) {
                this.isEn = val === 'en' ? true : false;
            },
            "$route.params.parentId": function (val) {
                this.parentId = atob(decodeURIComponent(val));
                this.filterId = this.parentId;
                this.getSidebar(this.parentId)
                sessionStorage.removeItem('productSearch');
            }
        },
        methods: {
            hideSelect(){
                this.popSelect1 = false;
                this.popSelect2 = false;
            },
            showSelect(el){
                this[el] = true;
            },
            search() {
                this.pageCurrent = 1;
                this.getList(true)
            },
            productClicked(index) {
                if (this.isSeriesFilter) {
                    if (this.filterIdx === 0) {
                        this.filterIdx = index + 1;
                        this.filterId = this.leftbar[index + 1].id;
                        this.pageCurrent = 1;
                        this.getList(false);
                    }
                }
            },
            chooseSeries(index) {
                if (this.$route.path === this.seriesBar[index].templateUrl) {
                    sessionStorage.removeItem('productSearch');
                } else {
                    this.$router.replace({
                        path: this.seriesBar[index].templateUrl
                    })
                }
                this.$router.go(0)
            },
            chooseProduct(index) {
                this.filterIdx = index;
                this.searchText = '';
                this.filterParticleId = '';
                this.filterParticleIdx = '';
                this.pageCurrent = 1;

                if (index === 0) {
                    this.filterId = '';
                    this.getSidebar(this.parentId);
                } else {
                    this.filterId = this.leftbar[index].id;
                    this.getList(index === 0);
                }

                this.hideSelect();
            },
            chooseParticle(index) {
                this.filterIdx = '';
                this.filterId = '';
                this.searchText = '';
                this.pageCurrent = 1;

                this.filterParticleIdx = index;
                this.filterParticleId = this.filterParticles[index].id;
                this.getList(true);

                this.hideSelect();
            },
            checkProductPage(type) {
                switch(type) {
                    case 'PREV':
                        if (this.productIdx > 0) {
                            this.productIdx--;
                        } else {
                            this.$message.warning('前面没有啦!~')
                        }
                        break;
                    case 'NEXT':
                        if (this.productIdx < this.productList.length - 1) {
                            this.productIdx++;
                        } else {
                            this.$message.warning('没有更多啦!~')
                        }
                        break;
                }
                this.checkDetail(this.productList[this.productIdx].id)
            },
            closeDialog() {
                this.dialogVisible = false;
                setTimeout(() => {
                    this.showDialog = false;
                }, 400)
            },
            checkDetail(id, index) {
                if (index) this.productIdx = index;
                // 恢复显示首张轮播图（再次打开详情轮播会显示之前关闭的内容）
                this.$refs.mySwiper.swiper.activeIndex = 0;
                this.$http.get(urls.findProduct + id).then(res => {
                    if (res.data.status === 200) {
                        this.detail = res.data.data;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
                this.showDialog = true;
                this.dialogVisible = true;
            },
            changePagination (page) {
                this.getList(false, page)
            },
            getList(isGetSeries, pageCurrent, pageSize) {
                const params = {}
                isGetSeries ? params.navigationParentId = this.parentId : params.navigationId = this.filterId || this.navId;
                if (this.searchText !== '' && this.searchText !== undefined && this.searchText !== null) params.text = this.searchText;
                if (this.filterParticleIdx !== '' && this.filterParticleIdx !== undefined) params.particle = this.filterParticleIdx + 1;
                params.pageCurrent = pageCurrent ? pageCurrent : this.pageCurrent;
                params.pageSize = pageSize ? pageSize : this.pageSize;
                
                this.$http.get(urls.getProductList, {params}).then(res => {
                    if (res.data.status === 200) {
                        this.productList = res.data.data.records;
                        this.total = res.data.data.total;
                        if (this.searchText) this.matchSidebarActiveItem()
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            matchSidebarActiveItem() {
                this.leftbar.map((item, index) => {
                    this.productList.map((subItem, subIndex) => {
                        if ((this.isEn ? subItem.navigation.enName : subItem.navigation.cnName) === (this.isEn ? item.enName : item.cnName)) {
                            this.filterIdx = index;
                            this.filterId = item.id
                        }
                    })
                })
            },
            getSidebar(id) {
                return new Promise((resolve, reject) => {
                    this.$http.get(urls.getNavChild + id).then(res => {
                        if (res.data.status === 200) {
                            let all = {
                                id: this.parentId,
                                cnName: '全部',
                                enName: 'ALL',
                            }
                            this.leftbar = JSON.parse(JSON.stringify(res.data.data));
                            this.leftbar.unshift(all);
                            this.productList = JSON.parse(JSON.stringify(res.data.data));
                            for (var i in this.leftbar) {
                                if (this.leftbar[i].id === this.filterId) {
                                    this.filterIdx = Number(i);
                                    break;
                                }
                            }
                        } else if (res.data.status !== 401 && res.data.status !== 106) {
                            this.$message.error(res.data.message)
                        }
                        resolve()
                    }).catch(err => {
                        reject(err)
                    })
                })
            },
            checkSearch(){
                const searchData = JSON.parse(sessionStorage.getItem('productSearch'));
                this.searchText = searchData.searchStr;
                this.seriesBar = searchData.series;
                this.isSeriesFilter = false;
                for (let i in this.seriesBar) {
                    if (this.seriesBar[i].id == this.parentId) {
                        this.seriesIdx = i;
                        break;
                    }
                }
                const params = {
                    pageCurrent: this.pageCurrent,
                    pageSize: this.pageSize
                }
                if (this.searchText) params.text = this.searchText;
                this.$http.get(urls.getProductList, {params}).then(res => {
                    if (res.data.status === 200) {
                        this.productList = res.data.data.records;
                        // 不适用数字0，不然会适配到产品系列那块内容。
                        this.filterIdx = null;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            descriptionDownload(url) {
                // const url = this.detail.fileExternalUrl;
                let a = document.createElement('a');
                a.href = url;
                a.download = this.isEn ? this.detail.enName : this.detail.cnName;
                a.setAttribute('target', '_blank');
                a.click();
            }
        },
        created() {
            this.parentId = atob(decodeURIComponent(this.$route.params.parentId));
            if (this.$route.query && this.$route.query.n) {
                this.navId = atob(decodeURIComponent(this.$route.query.n));
                this.filterId = Number(this.navId);
                this.getSidebar(this.parentId).then(() => {
                    this.getList(false);
                });
            } else {
                this.filterId = this.parentId;
                if (sessionStorage.getItem('productSearch') !== null) {
                    this.checkSearch();
                } else {
                    this.getSidebar(this.parentId)
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    @deep: ~'>>>';
    #product-list { 
        //background: url(/assets/img/product_list_bg.b0760f64.jpg) no-repeat top center;
        background: url("../../assets/images/products/product_list_bg.jpg") no-repeat top center;
        .content {
            // padding: 1% 5%;
            
            max-width: 1400px;
            margin: 0 auto;
            background-size: 100%;
            .search-bar {
                text-align: right;
                padding-top: 20px;
                margin-bottom: 40px;
                >span {
                    position: relative;
                    display: inline-block;
                    border: 1px solid #DC000C;
                    border-radius: 3vw;
                    height: 42px;
                    line-height: 42px;
                    &::before {
                        content: '';
                        display: inline-block;
                        height: 41px;
                        border-right: 1px solid #DC000C;
                        position: absolute;
                        left: 42px;
                    }
                    .icon-search {
                        display: inline-block;
                        width: 24px;
                        height: 24px;
                        background: url("../../assets/images/icon_search_red.png") no-repeat center center;
                        background-size: 100%;
                        position: relative;
                        top: 4px;
                        left: 10px;
                        cursor: pointer;
                    }
                    input {
                        font-size: 18px;
                        padding-left: 26px;
                        padding-right: 16px;
                    }
                }
            }
            .list {
                display: flex;
                justify-content: space-between;
                >.lf {
                    width: 200px;
                    font-size: 14px;
                    .filter-bar {
                        line-height: 40px;
                        color: #34254C;
                        h2 {
                            font-size: 24px;
                            color: #DC000C;
                            padding-bottom: 10px;
                            &::before {
                                content: '';
                                display: inline-block;
                                width: 1.1vw;
                                height: 1.1vw;
                                background: url('../../assets/images/icon_filter.png') no-repeat  center center;
                                background-size: 100%;
                                margin-right: 5px;
                                position: relative;
                                top: 4px;
                            }
                        }
                        .h5_1,
                        .h5_2{
                            display: none;
                        }
                        .selected {
                            position: relative;
                            text-decoration: underline;
                        }
                        .selected::before {
                            content: '';
                            display: inline-block;
                            border: 9px solid transparent;
                            border-left-color: #34254C;
                            border-left-width: 12px;
                            position: absolute;
                            top: 50%;
                            left: -20px;
                            margin-top: -8px;
                            border-radius: 5px;
                        }
                        ul{
                            padding-bottom: 20px;
                            li {
                                cursor: pointer;
                                font-size: 16px;
                                // font-weight: bold;
                                margin-bottom: 5px;
                            }
                        }
                            
                    }
                }
                .series {
                    flex: 1;
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-wrap: wrap;
                    margin-left: 0;
                    .item {
                        width: 23%;
                        margin: 0 0 50px 2%;
                        text-align: center;
                        background: #fff;
                        border-radius: 15px;
                        box-shadow: 0 30px 40px rgba(0, 0, 0, 0.07);;
                        img{
                            width: 100%;
                            height: 276px;
                            border-top-left-radius: 15px;
                            border-top-right-radius: 15px;
                        }
                        .text {
                            >p {
                                font-size: 16px;
                                margin-top: 20px;
                                color: #333;
                                font-weight: normal;
                            }
                        }
                        &.sub-item {
                            .text {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                padding: 1vw;
                                .lf {
                                    text-align: left;
                                    width: 55%;
                                    p {
                                        font-size: 18px;
                                        font-weight: bold;
                                        margin-bottom: 10px;
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                        word-break: break-all;
                                    }
                                    span {
                                        color: #999;
                                        font-size: 14px;
                                    }
                                }
                                button {
                                    padding: 10px 20px;
                                    border: 1px solid #ddd;
                                    border-radius: 50px;
                                    color: #DC000C;
                                    font-size: 14px;
                                    overflow: hidden;
                                    position: relative;
                                    transition: all .2s linear;
                                    &::before,
                                    &::after {
                                        content: '';
                                        position: absolute;
                                        top: 0;
                                        width: 50%;
                                        height: 100%;
                                        font-size: 14px;
                                        padding-top: 10px;
                                        background-color: #DC000C;
                                        transition: all .15s linear;
                                    }
                                    &::before {
                                        content: '查看';
                                        left: -55%;
                                        text-align: right;
                                        border-radius: 50px 0 0 50px;
                                    }
                                    &::after {
                                        content: '详情';
                                        right: -55%;
                                        text-align: left;
                                        border-radius: 0 50px 50px 0;
                                    }
                                    &:hover {
                                        color: #fff;
                                        &::before {
                                            left: 0;
                                        }
                                        &::after {
                                            right: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .list-empty {
                    flex: 8;
                    align-self: center;
                    text-align: center;
                    font-size: 18px;
                    margin-bottom: 100px;
                    >div {
                        width: 200px;
                        align-self: center;
                        height: 125px;
                        margin: 0 auto 20px;
                        overflow: hidden;
                    }
                }
            }
            .pagination {
                text-align: center;
                padding-left: 224px;
            }
        }
        .dialog {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(0,0,0,.7);
            z-index: 1003;
            .detail-box {
                width: 1180px;
                height: 640px;
                margin: 5% auto 0;
                padding: 2% 1% 2%;
                display: flex;
                border-radius: 10px 0 10px 0;
                justify-content: space-between;
                background: #fff url("../../assets/images/products/product_detail_bg.png") no-repeat center center;
                background-size: 100% 100%;
                position: relative;
                .icon-close {
                    position: absolute;
                    right: -11px;
                    top: -12px;
                    width: 24px;
                    height: 24px;
                    cursor: pointer;
                    background: url("../../assets/images/products/icon_close.png") no-repeat center center;
                    background-size: 100%;
                }
                .img {
                    width: 720px;
                    @{deep} .swiper-slide { 
                        height: 720px;
                        .banner-box {
                            margin: 15px 0 0 80px;
                        }
                    }
                    @{deep} .swiper-button-prev {
                        width: 60px;
                        height: 60px;
                        top: 40%;
                        background-image: url("../../assets/images/products/icon_arrow_left.png") !important;
                        background-size: contain;
                    }
                    @{deep} .swiper-button-next {
                        width: 60px;
                        height: 60px;
                        top: 40%;
                        right: 50px;
                        background-image: url("../../assets/images/products/icon_arrow_right.png") !important;
                        background-size: contain;
                    }
                    img {
                        width: 520px;
                        height: 520px;
                        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
                        border-radius: 8px;
                    }
                }
                .detail-info {
                    width: 275px;
                    margin-top: 25px;
                    h2 {
                        font-size: 28px;
                        margin-bottom: 20px;
                    }
                    ul {
                        li {
                            font-size: 18px;
                            line-height: 35px;
                            color: #34254C;
                        }
                        .manual {
                            margin-bottom: 15px;
                            span {
                                width: 4em;
                            }
                            p {
                                cursor: pointer;
                                color: #DC000C;
                                font-size: 15px;
                                padding-left: 22px;
                                line-height: 150%;
                                margin-bottom: 12px;
                                background: url("../../assets/images/down-load.png") no-repeat 2px 3px;
                                background-size: 16px;
                            }
                        }
                    }
                    .description {
                        cursor: pointer;
                        color: #DC000C;
                        font-size: 15px;
                        padding-left: 22px;
                        line-height: 150%;
                        margin-bottom: 12px;
                        background: url("../../assets/images/down-load.png") no-repeat 2px 3px;
                        background-size: 16px;
                    }
                    .purchase {
                        color: #34254C;
                        font-size: 18px;
                        >p {
                            margin: 15px 0 10px;
                        }
                        a {
                            display: inline-block;
                            font-size: 18px;
                            color: #E12319;
                            margin-right: 10px;
                            border-radius: 6px;
                            padding: 6px 24px 6px 32px;
                            background: url("../../assets/images/jd.png") no-repeat 6px center;
                            background-size: 24px;
                            border: 1px solid #FDE3E2;
                            position: relative;
                            &::after {
                                content: '';
                                display: inline-block;
                                width: 14px;
                                height: 14px;
                                background: url("../../assets/images/icon_play.png") no-repeat center center;
                                background-size: contain;
                                position: absolute;
                                right: 6px;
                                top: 10px;
                            }
                            &:last-child {
                                margin-right: 0;
                                background-image: url("../../assets/images/tmall.png");
                            }
                        }
                    }
                }
            }
        }
    }

    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem";
    }

    @media screen and (max-width: 750px) {
        #product-list{
            .content{
                width: 100%;
                padding: 0;
                .search-bar{
                    padding: 6px 10px;
                    background-color: #D93C45;
                    margin-right: 0;
                    margin-bottom: 0;
                    &>span{
                        background:#fff;
                        width: 100%;
                        .fontFn(36,height);
                        .fontFn(36,line-height);
                        border-radius: 6px;
                        &::before{
                            content:none;
                        }
                        .icon-search{
                            position: absolute;
                            right:4%;
                            top:50%;
                            left:auto;
                            .fontFn(20,width);
                            .fontFn(20,height);
                            transform: translateY(-50%);
                            -webkit-transform: translateY(-50%);
                            -moz-transform: translateY(-50%);
                            -ms-transform: translateY(-50%);
                            -o-transform: translateY(-50%);
                        }
                        input{
                            padding-left: 0;
                            padding-right: 14%;
                            width: 96%;
                            .fontFn(14);
                        }
                    }
                }
                .list{
                    flex-direction:column;
                    &>.lf{
                        width: 100%;
                        .filter-bar{
                            padding-left: 3%;
                            position: relative;
                            h2{
                                .fontFn(18);
                                .fontFn(18,line-height);
                                padding-top: 4%;
                                padding-right: 10%;
                                display: inline-block;
                                vertical-align: top;
                                &::before{
                                    .fontFn(16,width);
                                    .fontFn(16,height);
                                }
                            }
                            ul{
                                display: none;
                            }
                            .popBg{
                                position: fixed;
                                top:0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                background:rgba(0,0,0,0.3);
                                z-index: 500;
                            }
                            .h5_1,
                            .h5_2{
                                display: block;
                                width: 60%;
                                position: absolute;
                                z-index: 1000;
                                background:#fff;
                                padding: 4%;
                                box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.36);
                                border-radius: 10px;
                                li{
                                    line-height: 32px;
                                    font-weight: bolder;
                                    font-size: 14px;
                                }
                            }
                            .h5_2{
                                left:36%;
                            }
                            .selected{
                                padding-left: 14px;
                                font-size: 16px;
                                text-decoration: underline;
                                &::before{
                                    left:0;
                                    border-left-width: 10px;
                                }
                            }
                        }
                    }
                    .series{
                        flex: 1;
                        margin-left:3%;
                        padding: 2% 0;
                        .item{
                            width: 48%;
                            margin-right:2%;
                            margin-bottom:6%;
                            margin-left: 0;
                            height: auto;
                            img{
                                width: 100%;
                                .fontFn(175,height);
                            }
                            .text > p{
                                margin:0;
                                padding: 6% 4%;
                                .fontFn(12);
                            }
                        }
                        .item.sub-item{
                            .text{
                                padding:2% 4% 4%;
                                .lf{
                                    flex:auto;
                                    p{.fontFn(14)}
                                    span{.fontFn(12)}
                                }
                                button{
                                    flex:none;
                                    .fontFn(12);
                                    padding: 6px 12px;
                                }
                            }
                        }
                    }
                    .list-empty{
                        flex: 2;
                    }
                }
                .pagination {
                    padding-left: 0;
                }
            }

            .dialog{
                z-index: 1500000;
                .detail-box{
                    display: block;
                    width: 100%;
                    height: 100%;
                    margin:0;
                    border-radius: 0;
                    padding: 0;
                    background-image: none;
                    .img{
                        width: 100%;
                        overflow:hidden;
                        .fontFn(375,height);
                        .swiper-slide{
                            height:auto;
                            .banner-box{
                                margin-left: 0;
                            }
                        }
                        img{
                            width: 100%;
                            height: auto;
                            .fontFn(375,max-height);
                            border-radius: 0;
                        }
                        .swiper-button-prev,
                        .swiper-button-next{
                            background:none;
                            width: 40px;
                            height: 40px;
                            top:50%;
                            margin-top:-20px;
                            &::after{
                                content:'';
                                display: block;
                                position: absolute;
                                border-top:2px solid #625F6F;
                                border-left:2px solid #625F6F;
                                width: 12px;
                                height: 12px;
                                margin-left:-4px;
                                margin-top:-6px;
                                top:50%;
                                left:50%;
                            }
                        }
                        .swiper-button-prev{
                            left: 0;
                            &::after{
                                transform: rotate(-45deg);
                                -webkit-transform: rotate(-45deg);
                                -moz-transform: rotate(-45deg);
                                -ms-transform: rotate(-45deg);
                                -o-transform: rotate(-45deg);
                            }
                        }
                        .swiper-button-next{
                            right:0;
                            &::after{
                                transform: rotate(135deg);
                                -webkit-transform: rotate(135deg);
                                -moz-transform: rotate(135deg);
                                -ms-transform: rotate(135deg);
                                -o-transform: rotate(135deg);
                            }
                        }
                    }
                    .detail-info{
                        
                        width: 100%;
                        margin-top:0;
                        h2{
                            .fontFn(24);
                            margin-bottom: 0;
                            padding: 4% 4% 8%;
                            font-weight: normal;
                            color:#fff;
                            background:url('../../assets/images/products/product_detail_bg.png') no-repeat -8px 74%;
                            background-size: 150% auto;
                        }
                        ul{
                            padding:1% 4%;
                            font-size: 0;
                            li{
                                display: inline-block;
                                vertical-align: top;
                                width: 100%;
                                .fontFn(12);
                                .fontFn(20,line-height)
                            }
                        }
                    }
                    .icon-close{
                        z-index: 1500001;
                        right: auto;
                        left:10px;
                        top:20px;
                        background:rgba(0,0,0,0.5);
                        border-radius: 50%;
                        &::after{
                            content:'';
                            display: block;
                            position: absolute;
                            border-top:1px solid #fff;
                            border-left:1px solid #fff;
                            width: 8px;
                            height: 8px;
                            margin-left:-2px;
                            margin-top:-4px;
                            top:50%;
                            left:50%;
                            transform: rotate(-45deg);
                            -webkit-transform: rotate(-45deg);
                            -moz-transform: rotate(-45deg);
                            -ms-transform: rotate(-45deg);
                            -o-transform: rotate(-45deg);
                        }
                    }
                }
            }
        }
    }

    ::-webkit-input-placeholder { /* WebKit browsers */
      color: #EC9EBD;
    }

    ::-moz-placeholder { /* Mozilla Firefox 19+ */
      color: #EC9EBD;
    }

    :-ms-input-placeholder { /* Internet Explorer 10+ */
      color: #EC9EBD;
    }  
</style>